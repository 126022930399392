import React from "react"
import tw, { styled, css } from "twin.macro"

const units = ["rem", "px", "em", "%"]

const P = styled.p(({ size, color, thickness }) => [
  tw`mb-0 font-bold leading-none text-transparent uppercase font-mont`,
  css`
    -webkit-text-stroke-width: ${thickness ? thickness : "2px"};
    -webkit-text-stroke-color: white;
    font-size: 15rem;
  `,
  new RegExp(`^[0-9]+(${units.join("|")})$`).test(size) &&
    css`
      font-size: ${size};
    `,
  new RegExp(`^#(?:[0-9a-fA-F]{3}){1,2}$`).test(color) &&
    css`
      -webkit-text-stroke-color: ${color};
    `,
  css`
    @media (max-width: 1280px) {
      font-size: 12rem;
    }
    @media (max-width: 1024px) {
      font-size: 8rem;
    }
    @media (max-width: 640px) {
      font-size: 5rem;
    }
  `,
])

const Outline = ({ children, size, color, className, ...rest }) => (
  <P
    size={size}
    color={color}
    className={className ? className : "outline"}
    dangerouslySetInnerHTML={{ __html: children }}
    {...rest}
  ></P>
)

export default Outline
