import React from "react"
import tw, { styled, css } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faYoutube,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

const List = tw.ul`list-none flex -mx-4 flex-wrap items-center justify-center`

const Item = tw.li`px-4`

const Link = styled.a`
  ${tw`relative overflow-hidden z-10 flex items-center justify-center transition-all duration-500 after:(h-full w-full bg-marrone-primary content[''] absolute top-0 left-0 z-behind transform translate-y-full transition-all duration-500 ease-in-out) ease-in-out border-2 border-marrone-primary`}
  height: 50px;
  width: 50px;
  svg {
    width: 50px;
  }

  &:hover {
    ${tw`text-white after:translate-y-0`}
  }
`
const icons = {
  TW: faTwitter,
  FA: faFacebookF,
  YT: faYoutube,
}

const SocialMedia = ({ socials }) => (
  <List>
    {socials.map(({ url, platform }, index) => (
      <Item key={index}>
        <Link href={url} target="_blank" rel="noreferrer">
          {<FontAwesomeIcon icon={icons[platform]} />}
        </Link>
      </Item>
    ))}
  </List>
)

export default SocialMedia
