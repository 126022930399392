import { allNumbers } from "./regex"
import { cloudinaryURL, hostname } from "./constants"
import { graphql } from "gatsby"
import crypto from "crypto"

function stripPhoneNumber(number) {
  return number.replace(allNumbers, "")
}

function getImage(url, addHostname = false, addCloudinary = false) {
  return url
  let asset = ""
  if (addHostname) url = hostname + url
  process.env.NODE_ENV !== "development" && addCloudinary
    ? (asset = cloudinaryURL + url)
    : (asset = url)
  return asset
}

// Converts a flat menu that is returned by graphql
// into a hierarchical menu that is expected from wordpress.
function flatMenuToHierarchical(
  data = [],
  { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
) {
  const tree = []
  const childrenOf = {}
  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}

// Helper function for splitContent. Is not exported.
function splitContentOnIndex(html, index) {
  let secondHalf = html.slice(index)
  let firstHalf = html.slice(0, index)

  // Prune the the first half by removing all of the HTML after the last
  // div opening.
  firstHalf = firstHalf.slice(0, firstHalf.lastIndexOf("<div"))

  // Prune the second half by removing all of the HTML before the first
  // div closing.
  secondHalf = secondHalf.slice(secondHalf.indexOf("</div>") + 6)
  return [firstHalf, secondHalf]
}

// Splits every HTML in an array on a hash.
function splitAllContent(content, hash) {
  // The last element of the array is what we haven't split on yet.
  // Pop it off the list.
  const html = content.pop()
  const indexOfHash = html ? html.indexOf(hash) : undefined
  if (indexOfHash && indexOfHash !== -1) {
    const splitContent = splitContentOnIndex(html, indexOfHash)
    return content.concat(splitAllContent(splitContent, hash))
  } else return content.concat([html])
}

// Splits HTML content on a seperator custom block.
function splitContent(html, themeName) {
  const hash = crypto.createHash("sha256").update(themeName).digest("hex")
  return hash ? splitAllContent([html], hash) : [html]
}

function getCategories() {
  return graphql`
    query {
      allWpCategory(filter: { slug: { ne: "uncategorized" } }) {
        nodes {
          name
        }
      }
    }
  `
}

export {
  stripPhoneNumber,
  getImage,
  flatMenuToHierarchical,
  splitContent,
  getCategories,
}
