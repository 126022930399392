import React from "react"
import tw, { css, styled } from "twin.macro"

import ModalContext from "./modal-context"

const Modal = styled.div(() => [
  tw`flex fixed top-0 left-0 w-screen h-screen justify-center items-center transition-all duration-500 ease-in-out`,
  css`
    z-index: 1000;

    &:not(.open) {
      ${tw`opacity-0 pointer-events-none`}

      .modal-content {
        transform: scale(2);
      }
    }
  `,
])

const Overlay = styled.div(() => [
  tw`flex w-full h-full absolute top-0 left-0 bg-black`,
  css`
    opacity: 0.6;
    z-index: -1;
  `,
])

const CloseButton = styled.button(() => [
  tw`text-white transition-all duration-500 ease-in-out text-5xl right-0 absolute`,
  css`
    left: 100%;
    bottom: 100%;

    @media (max-width: 1300px) {
      left: inherit;
      right: 0;
    }

    &:hover {
      ${tw`text-marrone-primary`}
      transform: rotate(90deg);
    }
  `,
])

const Content = styled.div(() => [
  css`
    width: 95vw;
    max-width: 1200px;
  `,
  tw`relative bg-white shadow-lg font-mont transition-all duration-500 ease-in-out`,
])

export default () => (
  <ModalContext.Consumer>
    {modal => (
      <>
        <Modal className={modal.isOpen ? "open" : ""}>
          <Overlay onClick={modal.closeModal} />
          <Content className="modal-content">
            <CloseButton onClick={modal.closeModal}>×</CloseButton>
            <div
              id="modal-iframe"
              tw="w-full h-full"
              dangerouslySetInnerHTML={{ __html: modal.html }}
            ></div>
          </Content>
        </Modal>
      </>
    )}
  </ModalContext.Consumer>
)
