import React from "react"
import tw, { css, styled } from "twin.macro"
import { Link } from "gatsby"
import { stripPhoneNumber } from "../../../util/functions"

const ChildItem = styled(Link)`
  ${tw`relative flex w-full px-4 py-1 text-xs transition-all duration-500 ease-in-out`}
  white-space: pre;
  &:hover {
    ${tw`bg-marrone-primary`}
  }
`

const TopItem = styled(Link)`
  ${tw`relative flex w-full px-4 py-1 overflow-hidden text-center transition-all duration-500 ease-in-out whitespace-nowrap`}
  &:after {
    ${tw`absolute top-0 left-0 w-full h-full transition-all duration-500 ease-in-out bg-marrone-primary`};
    z-index: -1;
    content: "";
    transform: translateY(102%);
  }
`

const Nav = styled.nav(({ highContrast }) => [
  tw`relative flex-col items-end justify-center hidden font-bold font-mont 2xl:flex`,
  highContrast && tw`text-white`,
])

const List = tw.ul`flex items-center justify-start ml-0 mb-2`

const Children = styled.ul(({ child }) => [
  tw`absolute flex flex-col mt-0 ml-0 text-white transition-all duration-500 ease-in-out bg-marrone-black`,
  css`
    top: 100%;
    right: 0;
    min-width: 100%;
  `,
  child &&
    css`
      top: 0;
      right: 100%;
    `,
])

const Item = styled.li`
  ${tw`relative flex mb-0 text-sm`}

  & > ul {
    ${tw`text-sm opacity-0 pointer-events-none`}
  }

  &:hover {
    & > ul {
      ${tw`opacity-100! pointer-events-auto!`}
      pointer-events: auto !important;
    }

    & > .child {
      ${tw`bg-marrone-primary`}
    }

    & > a {
      ${tw`text-white`}
      &:after {
        transform: none;
      }
    }
  }
`

const Number = styled.a(({ highContrast }) => [
  tw`mr-4 text-2xl transition-all duration-500 ease-in-out text-marrone-primary hover:text-black`,
  highContrast && tw`hover:text-white`,
])

export default ({ menu, highContrast, number }) => {
  const listItems = (items, child = false) =>
    items.map(item => {
      return (
        <Item key={item.key}>
          {child ? (
            <ChildItem
              className="child"
              to={item.url}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ) : (
            <TopItem
              to={item.url}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          )}
          {item.children && (
            <Children child={child}>{listItems(item.children, true)}</Children>
          )}
        </Item>
      )
    })
  return (
    <Nav highContrast={highContrast}>
      <List>
        {listItems(menu)}
        <Item>
          <TopItem to="/blog/">Blog</TopItem>
        </Item>
        <Item>
          <TopItem to="/media-library/">Media Library</TopItem>
        </Item>
        <Item>
          <TopItem to="/giving-back/">Giving Back</TopItem>
        </Item>
        <Item>
          <TopItem to="/contact/">Contact</TopItem>
        </Item>
        <Item>
          <TopItem to="/pagina-principal/">Español</TopItem>
        </Item>
      </List>
      <Number
        highContrast={highContrast}
        href={"tel:" + stripPhoneNumber(number)}
      >
        {number}
      </Number>
    </Nav>
  )
}
