import React from "react"
import tw, { styled, css } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"
import Outline from "./outline"

const Button = styled.button(() => [
  tw`relative flex-col items-center justify-center hidden pt-1 text-xs font-bold text-white transition-all duration-500 ease-in-out bg-marrone-primary font-mont`,
  css`
    &.active,
    &:hover {
      ${tw`bg-marrone-hover`}
    }
    height: 65px;
    width: 70px;

    @media (max-width: 1439px) {
      ${tw`flex`}
    }

    @media (max-width: 640px) {
      ${tw`bg-transparent text-marrone-primary`}

      &:hover {
        ${tw`bg-white`}
      }
    }
  `,
])

const Line = styled.div(() => [
  tw`flex mt-1 bg-white`,
  css`
    width: 60%;
    height: 1px;

    &:nth-child(2) {
      transform: translateX(10%);
    }

    @media (max-width: 640px) {
      ${tw`bg-marrone-primary`}
    }
  `,
])

const CloseButton = styled.button(() => [
  tw`absolute flex items-center justify-center p-0 text-2xl text-white transition-all duration-500 ease-in-out bg-marrone-primary`,
  css`
    border-radius: 50%;
    right: 1rem;
    top: 1rem;
    width: 50px;
    height: 50px;

    &:hover {
      ${tw`bg-marrone-hover`}
    }

    p {
      ${tw`mb-0`}
    }
  `,
])

const Nav = styled.nav(() => [
  tw`fixed top-0 left-0 w-full max-h-full pr-6 overflow-auto transition-all duration-500 ease-in-out bg-white`,
  css`
    transform: translateY(-100%);
    padding-top: 7rem;
    &.active {
      transform: none;
    }

    .logo {
      ${tw`absolute `}
      left: 1rem;
      top: 1rem;
    }

    .outline {
      ${tw`absolute left-0 text-6xl`}
      top: 125px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      left: -1rem;
      z-index: -1;
      -webkit-text-stroke-width: 1px;
    }
  `,
])

const Item = tw.li`font-mont font-bold relative`

const Link = tw(
  GatsbyLink
)`transition-all duration-500 ease-in-out hover:text-marrone-primary`

const Index = tw.span`text-marrone-primary mr-2`

const Children = styled.ul(() => [
  tw`flex flex-col mt-0 ml-4 overflow-hidden transition-all duration-500 ease-in-out max-h-0`,
  css`
    li:first-child {
      ${tw`mt-2`}
    }
  `,
])

const ExpandButton = styled.button(() => [
  tw`absolute top-0 right-0 ml-1 text-base text-white transition-all duration-500 ease-in-out bg-marrone-primary`,
  css`
    border-radius: 50%;
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
    &:hover,
    &.active {
      ${tw`bg-marrone-hover`}
    }

    &.active {
      transform: rotate(180deg);
    }
  `,
])

const Overlay = styled.div(() => [
  tw`fixed top-0 left-0 w-full h-full transition-all duration-500 ease-in-out bg-white opacity-0 pointer-events-none`,
  css`
    &.active {
      ${tw`pointer-events-auto`}
      opacity: 0.6;
    }
  `,
])

const Logo = styled.img(() => [
  css`
    width: 80px;
  `,
])

export default class MobileNavigation extends React.Component {
  state = {
    isOpen: false,
  }

  onClick = () => {
    const isOpen = !this.state.isOpen
    this.setState({ isOpen: isOpen })
  }

  openChildren = (item, addedHeight = 0) => {
    if (item) {
      const parentItem = item.parentElement?.closest(".mobile-item")
      const children = item.querySelector("ul")
      this.openChildren(
        parentItem,
        addedHeight + item.scrollHeight + children.scrollHeight
      )
      children.style.maxHeight = `${children.scrollHeight + addedHeight + 16}px`
    }
  }

  toggleScroll = () => {
    document.body.style.overflowY = this.state.isOpen ? "hidden" : null
  }

  closeChildren = item => {
    const children = item.querySelector("ul")
    children.style.maxHeight = null
  }

  toggleChildren = e => {
    e.preventDefault()
    const button = e.target.closest("button")
    button.classList.toggle("active")
    button.innerHTML = button.classList.contains("active")
      ? "&minus;"
      : "&times;"

    const item = button.closest(".mobile-item")
    return button.classList.contains("active")
      ? this.openChildren(item)
      : this.closeChildren(item)
  }

  listItems = (items, child = false) =>
    items.map((item, index) => (
      <Item key={index} className="mobile-item">
        {!child && <Index>{`${index < 10 ? "0" : ""}${index + 1} /`}</Index>}
        <Link to={item.url} dangerouslySetInnerHTML={{ __html: item.title }} />
        {item.children && item.children.length !== 0 && (
          <>
            <ExpandButton onClick={this.toggleChildren}>&times;</ExpandButton>
            <br />
            <Children>{this.listItems(item.children, true)}</Children>
          </>
        )}
      </Item>
    ))

  render() {
    const { menu, mobileLogo } = this.props
    return (
      <>
        <Button
          className={this.state.isOpen && "active"}
          onClick={() => this.onClick()}
        >
          <Line />
          <Line />
          <Line />
          menu
        </Button>
        <Overlay
          onClick={this.onClick}
          className={this.state.isOpen && "active"}
        />
        <Nav className={this.state.isOpen && "active"}>
          <Link to="/" onClick={() => this.onClick()} className="logo">
            <Logo src={mobileLogo} alt="Marrone Law Firm Logo" />
          </Link>
          <CloseButton onClick={() => this.onClick()}>
            <p>&times;</p>
          </CloseButton>
          <ul tw="sm:pl-4 pb-4">
            {this.listItems(menu)}
            <Item className="mobile-item">
              <Index>06 /</Index>
              <Link to="/blog/">Blog</Link>
            </Item>
            <Item className="mobile-item">
              <Index>07 /</Index>
              <Link to="/media-library/">Media Library</Link>
            </Item>
            <Item className="mobile-item">
              <Index>08 /</Index>
              <Link to="/giving-back/">Giving Back</Link>
            </Item>
            <Item className="mobile-item">
              <Index>09 /</Index>
              <Link to="/contact/">Contact</Link>
            </Item>
            <Item className="mobile-item">
              <Index>10 /</Index>
              <Link to="/pagina-principal/">Español</Link>
            </Item>
          </ul>
          <Outline color="#f7f3e6">menu</Outline>
        </Nav>
      </>
    )
  }
}
