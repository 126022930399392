import React from "react"
import tw, { css, styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)(() => [
  tw`mx-2 transition-all duration-500 ease-in-out`,
  css`
    &:hover {
      ${tw`text-marrone-primary`}
    }
  `,
])

const Wrapper = tw.p`absolute font-bold text-white text-center bottom-0`

const Copyright = () => (
  <Wrapper>
    &copy; {new Date().getFullYear()} Marrone Law Firm LLC. All Rights Reserved.
    <Link to="/sitemap/">Sitemap</Link>|
    <Link to="/privacy-policy/">Privacy Policy</Link>|
    <Link to="/disclaimer/">Disclaimer</Link>
  </Wrapper>
)

export default Copyright
