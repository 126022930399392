import { sizeUnits, hostname } from "./constants.js"

// Regular expression to capture every valid CSS size
// e.g: 2.25rem, 0.25em, 2px, ...
const cssSize = `^[0-9]*.?[0-9]*(${sizeUnits.join("|")})$`

// Regular expression to capture every valid hex
// color.
const hexColor = `^#(?:[0-9a-fA-F]{3}){1,2}$`

// Captures relative links (/contact)
const relativeLink = `^(?!www.|(?:http|ftp)s?://|[A-Za-z]:\\|//).*`
// Captures internal links that are not relative
const internalLink = hostname

// Gets all numbers from a string.
const allNumbers = /\D/g

const URIFragment = new RegExp(`^#.*`)

const dataAttributeContains = (attribute, contains) => {
  return new RegExp(`/data-${attribute}=('|")${contains}('|")/g`)
}

const isValidSize = str => new RegExp(cssSize).test(str)

export {
  cssSize,
  hexColor,
  internalLink,
  relativeLink,
  allNumbers,
  dataAttributeContains,
  URIFragment,
  isValidSize,
}
