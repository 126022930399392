import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { flatMenuToHierarchical } from "../../../util/functions"
import Logo from "../modules/firm-logo"
import DesktopNavigation from "../modules/desktop-navigation"
import MobileNavigation from "../modules/mobile-navigation"
import Modal from "../modules/modal"
import tw, { styled, css } from "twin.macro"

const Head = styled.header(({ highContrast }) => [
  tw`absolute top-0 left-0 flex items-start justify-between w-full p-4 pt-6`,
  css`
    z-index: 500;
    max-width: 100vw;

    @media (max-width: 640px) {
      ${tw`fixed top-0 left-0 items-center py-0 shadow-lg bg-marrone-black`}
      height: 80px;
      img {
        ${tw`mb-0`}
        max-width: 52.5vw;
      }
      img.firm-logo {
        ${tw`mb-0`}
        width: initial;
        height: 40px;
      }
      @media (max-width: 640px) {
        .desktop-logo {
          ${tw`hidden`}
        }
      }
      @media (min-width: 641px) {
        a.mobile-logo {
          ${tw`hidden!`}
        }
      }
    }
  `,
])

class Header extends React.Component {
  render() {
    const { logo, normalLogo, highContrast } = this.props
    return (
      <>
        <Modal />
        <Head className="header" highContrast={highContrast}>
          <Logo
            highContrast={highContrast}
            alt="Marrone Law Firm Logo"
            className={logo !== normalLogo ? "desktop-logo" : "firm-logo"}
          />
          {logo !== normalLogo && (
            <Logo
              src={normalLogo}
              alt="Marrone Law Firm Logo"
              className="mobile-logo"
            />
          )}
          <DesktopNavigation {...this.props} />
          <MobileNavigation {...this.props} />
        </Head>
      </>
    )
  }
}

export default ({ highContrast, spanish }) => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          header {
            header {
              firmLogo {
                localFile {
                  publicURL
                }
              }
              firmHighContrastLogo {
                localFile {
                  publicURL
                }
              }
              firmMobileLogo {
                localFile {
                  publicURL
                }
              }
              firmHighContrastMobileLogo {
                localFile {
                  publicURL
                }
              }
            }
          }
          contactInfo {
            contactInfo {
              firmNumber
            }
          }
        }
        mainMenu: wpMenu(slug: { eq: "main-menu" }) {
          menuItems {
            nodes {
              label
              url
              title: label
              key: id
              parentId
            }
          }
        }
        spanishMenu: wpMenu(slug: { eq: "main-menu-spanish" }) {
          menuItems {
            nodes {
              label
              url
              title: label
              key: id
              parentId
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.wp.header.header
      const props = {
        menu: spanish
          ? flatMenuToHierarchical(data.spanishMenu.menuItems.nodes)
          : flatMenuToHierarchical(data.mainMenu.menuItems.nodes),
        number: data.wp.contactInfo.contactInfo.firmNumber,
        logo: highContrast
          ? fields.firmLogo.localFile.publicURL
          : fields.firmHighContrastLogo.localFile.publicURL,
        normalLogo: fields.firmLogo.localFile.publicURL,
        highContrast: highContrast,
        mobileLogo: fields.firmMobileLogo.localFile.publicURL,
        mobileLogoHc: fields.firmHighContrastMobileLogo.localFile.publicURL,
      }
      return (
        <>
          <Header {...props} highContrast={highContrast} />
        </>
      )
    }}
  />
)
