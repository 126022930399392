import React from "react"
import Player from "@vimeo/player"

const defaultState = {
  isOpen: false,
  html: "",
  modalRef: null,
  toggleModal: () => {},
  openModal: () => {},
  closeModal: () => {},
  setHTML: () => {},
}

const ModalContext = React.createContext(defaultState)

export class ModalProvider extends React.Component {
  state = {
    isOpen: false,
    html: "",
    modalRef: null,
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openModal = (html = "") => {
    this.lockScrollBar()
    this.setState({ isOpen: true, html: html }, this.afterOpen)
  }

  afterOpen() {
    // Autoplay the embedded vimeo video
    const iframe = document.querySelector("#modal-iframe iframe")
    if (iframe) new Player(iframe).play()

    // Bind escape to closing the modal
    document.onkeydown = e => {
      e.key === "Escape" && this.closeModal()
    }
  }

  closeModal = () => {
    this.unlockScrollBar()
    this.setState({ isOpen: false })

    // Autopause the embedded vimeo video
    const iframe = document.querySelector("#modal-iframe iframe")
    if (iframe) new Player(iframe).pause()

    // Unbind the escape binding
    document.onkeydown = null
  }

  setHTML = html => {
    this.setState({ html: html })
  }

  lockScrollBar = () => {
    document.body.style.overflow = "hidden"
  }

  unlockScrollBar = () => {
    document.body.style.overflow = null
  }

  render() {
    const { children } = this.props
    const { isOpen, html, modalRef } = this.state
    return (
      <ModalContext.Provider
        value={{
          isOpen,
          html,
          modalRef,
          toggleModal: this.toggleModal,
          openModal: this.openModal,
          closeModal: this.closeModal,
        }}
      >
        {children}
      </ModalContext.Provider>
    )
  }
}

export default ModalContext
