import React from "react"
import tw, { styled, css } from "twin.macro"
import { stripPhoneNumber } from "../../../util/functions"

const List = tw.ul`flex text-black font-bold font-mont relative flex-col items-center justify-start sm:w-1/2`

const Heading = tw.p`text-2xl text-marrone-primary`

const Item = styled.li(() => [
  tw`relative flex flex-col items-center justify-center my-16`,
  css`
    @media (max-width: 860px) {
      ${tw`my-8`}
    }
  `,
])

const Line = tw.p`mb-2 text-center`

const MainNumber = styled.a(() => [
  tw`mt-4 mb-6 text-3xl transition-all duration-500 ease-in-out text-marrone-primary`,
  css`
    &:hover {
      ${tw`text-black`}
    }
  `,
])

const Number = styled.a(() => [
  tw`transition-all duration-500 ease-in-out`,
  css`
    &:hover {
      ${tw`text-marrone-primary`}
    }
  `,
])

const Directions = styled.a(() => [
  tw`relative pb-2 transition-all duration-500 ease-in-out text-marrone-primary`,
  css`
    &:after {
      ${tw`absolute bottom-0 left-0 w-full transition-all duration-500 ease-in-out bg-marrone-primary`}
      height: 2px;
      content: "";
    }
    &:hover {
      ${tw`text-black`}

      &:after {
        ${tw`bg-black`}
      }
    }
  `,
])

const AddressList = ({ items, className }) => {
  const listItems = items.map(
    (
      { addressL1, addressL2, addressMapsUrl, faxNumber, phoneNumber, title },
      index
    ) => {
      let phoneHref
      let faxHref

      phoneNumber
        ? (phoneHref = `tel:${stripPhoneNumber(phoneNumber)}`)
        : (phoneHref = "")
      faxNumber
        ? (faxHref = `tel:${stripPhoneNumber(faxNumber)}`)
        : (faxHref = "")

      return (
        <Item key={index}>
          <Heading tw="text-center">{title}</Heading>
          <Line>{addressL1}</Line>
          {addressL2 && <Line>{addressL2}</Line>}
          {phoneNumber && (
            <Line>
              Local: <Number href={phoneHref}>{phoneNumber}</Number>
            </Line>
          )}
          {faxNumber && (
            <p>
              Fax: <Number href={faxHref}>{faxNumber}</Number>
            </p>
          )}
          {phoneNumber && (
            <MainNumber href={phoneHref}>{phoneNumber}</MainNumber>
          )}
          {addressMapsUrl && (
            <Directions href={addressMapsUrl}>MAP & DIRECTIONS</Directions>
          )}
        </Item>
      )
    }
  )
  return <List className={className}>{listItems}</List>
}

export default AddressList
